.list .action {
  cursor: pointer;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.max-width-500 {
  max-width: 500px;
}

.max-width-150 {
  max-width: 150px;
  overflow: auto;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.home-title {
  font-size: 3rem;
  text-align: center;
}

.loader {
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader_cube {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.loader_cube--glowing {
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.loader_cube--color {
  z-index: 1;
  filter: blur(2px);
  background: linear-gradient(135deg, #1afbf0, #da00ff);
  animation: loadtwo 2.5s ease-in-out infinite;
}

@keyframes loadtwo {
  50% {
    transform: rotate(-80deg);
  }
}

@keyframes square-animation {
  0% {
   left: 0;
   top: 0;
  }
 
  10.5% {
   left: 0;
   top: 0;
  }
 
  12.5% {
   left: 32px;
   top: 0;
  }
 
  23% {
   left: 32px;
   top: 0;
  }
 
  25% {
   left: 64px;
   top: 0;
  }
 
  35.5% {
   left: 64px;
   top: 0;
  }
 
  37.5% {
   left: 64px;
   top: 32px;
  }
 
  48% {
   left: 64px;
   top: 32px;
  }
 
  50% {
   left: 32px;
   top: 32px;
  }
 
  60.5% {
   left: 32px;
   top: 32px;
  }
 
  62.5% {
   left: 32px;
   top: 64px;
  }
 
  73% {
   left: 32px;
   top: 64px;
  }
 
  75% {
   left: 0;
   top: 64px;
  }
 
  85.5% {
   left: 0;
   top: 64px;
  }
 
  87.5% {
   left: 0;
   top: 32px;
  }
 
  98% {
   left: 0;
   top: 32px;
  }
 
  100% {
   left: 0;
   top: 0;
  }
 }
 
 .loader {
  position: relative;
  width: 96px;
  height: 96px;
  transform: rotate(45deg);
 }
 
 .loader-square {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 0px;
  background: white;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: square-animation 10s ease-in-out infinite both;
 }
 
 .loader-square:nth-of-type(0) {
  animation-delay: 0s;
 }
 
 .loader-square:nth-of-type(1) {
  animation-delay: -1.4285714286s;
 }
 
 .loader-square:nth-of-type(2) {
  animation-delay: -2.8571428571s;
 }
 
 .loader-square:nth-of-type(3) {
  animation-delay: -4.2857142857s;
 }
 
 .loader-square:nth-of-type(4) {
  animation-delay: -5.7142857143s;
 }
 
 .loader-square:nth-of-type(5) {
  animation-delay: -7.1428571429s;
 }
 
 .loader-square:nth-of-type(6) {
  animation-delay: -8.5714285714s;
 }
 
 .loader-square:nth-of-type(7) {
  animation-delay: -10s;
 }

 .card {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  height: 400px;
  width: 300px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
}

.card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.card p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.card a {
  display: block;
  font-size: 18px;
  color: #0077cc;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}

.article-list {
  text-align: center;
}

.card {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  height: 550px;
  width: 300px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-text {
/* padding: 1rem; */
}

/* Add this style to create a grid layout */
.card-columns {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
grid-gap: 1rem;
}

/* Add this style to remove the default padding and margin from the ul element */
.card-deck ul {
margin: 0;
padding: 0;
}

/* Add this style to adjust the spacing between cards */
.card-deck .card {
margin: 1rem;
}

/* Add this style to make the card header and footer text centered */
.card-header, .card-footer {
text-align: center;
}

/* Add this style to make the card footer text italic */
.card-footer {
font-style: italic;
}

/* Add this style to make the card border rounded */
.card {
border-radius: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  align-items: flex-start;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.card-body .form-group {
  margin-bottom: 10px;
}

.card-body .form-group input {
  margin-right: 10px;
}

.card-body button {
  margin-top: 10px;
}

