.shop-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.shop-card {
  background-color: #f7f7f7;
  padding: 20px;
  margin: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* flex: 1 0 calc(33.333% - 20px); */
  width: 100%;
  max-height: 1700px;
  object-fit: scale-down;
}

.shop-card img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.shop-card label {
  font-weight: bold;
  margin-right: 5px;
}

.inactive-card {
  background-color: red;
}

.inactive-label {
  text-align: center;
  font-weight: bold;
  color: white; /* Set text color for the "Nedostupno" label */
  background-color: red; /* Set background color for the "Nedostupno" label */
  padding: 20px;
}

.brand-filter {
  max-width: 200px;
  min-width: 200px;
  padding: 1rem;
  border-right: 1px solid #ccc;
  width: 100%;
}

.brand-filter .btn {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  .brand-filter {
    max-width: 100%;
    margin-bottom: 1rem;
    border-right: none;
    border-bottom: 1px solid #ccc;
    width: 200px;
  }

  .shop-card {
    flex: 1 0 calc(50% - 20px);
    width: 300px;
    max-height: 700px;
  }

  .filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .img-fluid {
    width: 100%;
    max-height: 250px;
    object-fit: scale-down;
  }

  .d-flex {
    flex-direction: column;
  }

  .shop-container {
    justify-content: center; /* Center content on mobile screens */
  }
}

@media (max-width: 480px) {
  .shop-card {
    flex: 1 0 100%;
  }
}

.shop-main-container {
  margin-top: 1rem;
}

.navbar {
  z-index: 100;
}

@media (max-width: 767px) {
  .d-flex.shop-main-container {
    flex-direction: column;
  }

  .brand-filter {
    margin-top: 1rem;
  }

  .hide-on-mobile {
    display: none;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-group {
  display: flex;
  align-items: center; /* Add this line */
  justify-content: center;
}

.input-group input[type="number"] {
  /* margin-top: 20px; */
  text-align: center;
}

.input-group .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-group input[type="number"] {
  padding: 0;
}
