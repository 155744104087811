
/* Added styles for the credits section */
.credits {
    margin-top: 2rem;
    text-align: center;
  }
  
  .credits p {
    font-size: 0.9rem;
    color: #666;
  }
  
  .credits a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
  }
  
  .credits a:hover {
    text-decoration: underline;
  }
  