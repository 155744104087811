.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 0 20px; /* Added padding for smaller screens */
}

.login-form {
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-form h1 {
  margin-bottom: 1.5rem;
  color: #007bff;
  font-weight: 600;
  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.form-group label {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-group input {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
  text-align: center; /* Center the error message */
}

button[type="submit"] {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s, transform 0.3s;
}

button[type='submit']:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

button[type='submit']:active {
  transform: translateY(0);
  box-shadow: none;
}

/* Added styles for the credits section */
.credits {
  margin-top: 2rem;
  text-align: center;
}

.credits p {
  font-size: 0.9rem;
  color: #666;
}

.credits a {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.credits a:hover {
  text-decoration: underline;
}
