.loading-animation {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

h3 {
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  display: flex;
}

h3 i {
  margin-right: 10px;
  color: #3498db;
  font-size: 30px;
}

.basket-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

.table-margin {
  margin-left: 0px;
}

.basket-container h3 {
  margin-bottom: 20px;
}

.basket-container table {
  margin-bottom: 20px;
}

.basket-container th {
  text-align: left;
}

.basket-container td {
  text-align: left;
}

.total-cost {
  margin-top: 20px;
  margin-right: 50px;
  text-align: left;
}

.form-control.adresa-slanja {
  max-width: 400px;
}

.btn-success {
  background-color: green;
  color: white;
  margin-top: 20px;
  margin-right: 50px;
  float: left;
}

.form-control.input-width

.input-width {
  min-width: 300px;
  max-width: 300px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .basket-container {
      padding: 10px;
      margin-top: 10px;
  }

  .basket-container h3 {
      font-size: 18px;
      margin-bottom: 15px;
  }

  .basket-container table {
      font-size: 14px;
      margin-bottom: 10px;
  }

  .basket-container th,
  .basket-container td {
      padding: 8px;
  }

  .total-cost {
      margin-top: 10px;
      margin-right: 25px;
  }

  .btn-success {
      font-size: 14px;
      margin-top: 10px;
      margin-right: 25px;
  }

  .hide-on-mobile {
      display: none;
  }
}

.basket-container select {
  width: 100%;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .quantity-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .quantity-buttons .input-group-prepend,
  .quantity-buttons .input-group-append {
      width: 100%;
  }
  .quantity-buttons .input-group-prepend button,
  .quantity-buttons .input-group-append button {
      width: 100%;
  }
  .quantity-buttons .form-control.input-width {
      width: 100%;
      margin: 10px 0; /* Add some vertical spacing */
  }
}

