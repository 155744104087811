.table-margin {
    margin-left: 40px;
}

.search-criteria {
    padding-left: 10px;
}

.custom-input {
    width: 50%; /* Adjust this value according to your needs */
    margin: auto;
}

.list {
    margin-top: 20px;
  }
  
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    font-weight: bold;
  }
  
  .table tbody tr {
    transition: background-color 0.3s ease;
  }
  
  .table tbody tr:hover {
    background-color: #f2f2f2;
  }
  
  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  @media screen and (max-width: 767px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  
