.forbidden-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    padding: 15px;
    box-sizing: border-box;
  }
  
  .forbidden-title {
    color: #dc3545;
    font-size: 3rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .forbidden-message {
    color: #6c757d;
    font-size: 1.25rem;
    text-align: center;
  }
  
  /* For tablets and small desktops */
  @media (min-width: 768px) {
    .forbidden-title {
      font-size: 3.5rem;
    }
  
    .forbidden-message {
      font-size: 1.5rem;
    }
  }
  
  /* For medium desktops */
  @media (min-width: 992px) {
    .forbidden-title {
      font-size: 4rem;
    }
  
    .forbidden-message {
      font-size: 1.75rem;
    }
  }
  
  /* For large desktops and bigger */
  @media (min-width: 1200px) {
    .forbidden-title {
      font-size: 4.5rem;
    }
  
    .forbidden-message {
      font-size: 2rem;
    }
  }
  